import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormArray,
  ReactiveFormsModule,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import {
  CryptlexApiService,
  CtxDynamicForm,
  CtxStepComponent,
  CtxStepperComponent,
  DataCacheService,
} from 'ng-ui';
import {
  ALL_COUNTRIES,
  originalOrder,
  ResourceCreateFormData,
  ResourceUpdateFormData,
} from 'utils';
import { CtxButtonComponent } from 'ng-ui';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CtxAlertComponent } from 'ng-ui';
import { CommonModule } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

interface FeatureEntitlement {
  id: string;
  featureId: string;
  value: string;
}

@Component({
  selector: 'ctx-entitlement-set-form',
  templateUrl: './entitlement-set-form.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogTitle,
    CtxStepComponent,
    ReactiveFormsModule,
    CtxAlertComponent,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
    MatSelectModule,
    MatOptionModule,
    CtxStepperComponent,
  ],
})
export class EntitlementSetFormComponent
  extends CtxDynamicForm
  implements OnInit
{
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceCreateFormData | ResourceUpdateFormData,
    fb: FormBuilder,
    public dialogRef: MatDialogRef<EntitlementSetFormComponent>,
    private dataCacheService: DataCacheService,
    private apiService: CryptlexApiService
  ) {
    super(fb);
  }

  ALL_COUNTRIES = ALL_COUNTRIES;
  originalOrder = originalOrder;

  detailsTab: FormControl;
  featuresTab: FormArray;
  features = this.dataCacheService.getCachedValues('feature');
  selectedFeatureIds: Set<string> = new Set();
  existingFeatureEntitlements: FeatureEntitlement[] = [];

  async ngOnInit(): Promise<void> {
    this.formGroup = this.fb.group({
      name: new FormControl(
        {
          value: this.getInitialControlValue('name', this.dialogData),
          disabled: this.dialogData.mode === 'Update',
        },
        [Validators.required, Validators.maxLength(256)]
      ),
      displayName: [
        this.getInitialControlValue('displayName', this.dialogData) || '',
        [Validators.required, Validators.maxLength(256)],
      ],
      description: [
        this.getInitialControlValue('description', this.dialogData) || '',
        Validators.maxLength(256),
      ],
      features: this.fb.array([]),
    });

    this.detailsTab = this.fb.control([
      this.formGroup.get('name'),
      this.formGroup.get('displayName'),
      this.formGroup.get('description'),
    ]);

    this.featuresTab = this.formGroup.get('features') as FormArray;

    if (this.dialogData.mode === 'Update') {
      const entitlementSetId = this.dialogData.resource.id;

      const response = await this.apiService
        .get(`/v3/entitlement-sets/${entitlementSetId}/feature-entitlements`)
        .then((res) => {
          return res.body;
        });
      this.existingFeatureEntitlements = response || [];

      // Add existing features to form array
      this.existingFeatureEntitlements.forEach((entitlement) => {
        this.selectedFeatureIds.add(entitlement.featureId);
        this.addFeature(
          entitlement.featureId,
          entitlement.value,
          entitlement.id
        );
      });
    }
  }

  addFeature(featureId = '', value = '', entitlementId = '') {
    const featureForm = this.fb.group({
      featureId: new FormControl(
        {
          value: featureId,
          disabled: !!entitlementId,
        },
        [Validators.required]
      ),
      value: [value, Validators.required],
      entitlementId: [entitlementId],
    });

    this.featuresTab.push(featureForm);
    if (featureId) {
      this.selectedFeatureIds.add(featureId);
    }
  }
  onFeatureSelected() {
    // Update the selectedFeatureIds set based on the current form values
    this.selectedFeatureIds.clear();
    this.featuresTab.controls.forEach((control) => {
      const featureId = control.get('featureId')?.value;
      if (featureId) {
        this.selectedFeatureIds.add(featureId);
      }
    });
  }
  // Remove feature and handle API delete if needed
  async removeFeature(index: number) {
    const feature = this.featuresTab.at(index);
    const featureId = feature?.get('featureId')?.value;
    const entitlementId = feature?.get('entitlementId')?.value;
    if (entitlementId && this.dialogData.mode === 'Update') {
      const entitlementSetId = this.dialogData.resource.id;
      this.asyncRequests.push(async () => {
        return await this.apiService.delete(
          `/v3/entitlement-sets/${entitlementSetId}/feature-entitlements/${entitlementId}`
        );
      });
    }

    if (featureId) {
      this.selectedFeatureIds.delete(featureId);
    }
    this.removeFormArrayField(this.featuresTab, index);
  }

  isFeatureSelected(featureId: string): boolean {
    return this.selectedFeatureIds.has(featureId);
  }
  get availableFeatures() {
    return this.features.filter((feature: any) => {
      return !this.selectedFeatureIds.has(feature.id);
    });
  }

  // Handle form submission with proper create/update/delete logic
  override async submit(
    _formValue: any,
    dialogData: ResourceCreateFormData | ResourceUpdateFormData,
    dialogRef: MatDialogRef<any, any>,
    ctxTab: CtxStepperComponent
  ): Promise<void> {
    if (this.formGroup.invalid) {
      ctxTab.handleInvalidForm();
      return;
    }
    try {
      this._startFormSubmission(dialogRef);

      const rawFormValue = this.formGroup.getRawValue();

      const entitlementSetData = {
        name: rawFormValue.name,
        displayName: rawFormValue.displayName,
        description: rawFormValue.description,
      };

      let entitlementSetId = '';
      if (dialogData.mode === 'Create') {
        // Create new entitlement set
        const response = await this.apiService.post(
          '/v3/entitlement-sets',
          entitlementSetData
        );
        entitlementSetId = response.body?.id;
      } else {
        // Update existing entitlement set
        entitlementSetId = dialogData.resource.id;
        if (
          !this.formGroup.get('displayName')?.pristine ||
          !this.formGroup.get('description')?.pristine
        ) {
          this.asyncRequests.push(async () => {
            return await this.apiService.patch(
              `/v3/entitlement-sets/${entitlementSetId}`,
              entitlementSetData
            );
          });
        }
      }

      rawFormValue.features.map((feature: any, index: number) => {
        const featureControl = this.featuresTab.at(index);
        if (feature.entitlementId) {
          if (!featureControl.get('value')?.pristine) {
            this.asyncRequests.push(async () => {
              return await this.apiService.patch(
                `/v3/entitlement-sets/${entitlementSetId}/feature-entitlements/${feature.entitlementId}`,
                {
                  featureId: feature.featureId,
                  value: feature.value,
                }
              );
            });
          }
        } else {
          // Create new feature entitlement
          this.asyncRequests.push(async () => {
            return this.apiService.post(
              `/v3/entitlement-sets/${entitlementSetId}/feature-entitlements`,
              {
                featureId: feature.featureId,
                value: feature.value,
              }
            );
          });
        }
      });
      await this._handleAsyncRequests(entitlementSetId);
      await this._afterSubmissionSuccess({ ok: true }, dialogRef);
    } catch (errorResponse) {
      this._handleFormError(errorResponse, dialogRef);
    } finally {
      this._endFormSubmission();
    }
  }
}

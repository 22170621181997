import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxForm } from 'ng-ui';
import { CtxStepperComponent } from 'ng-ui';
import { ALL_COUNTRIES, CtxValidators, originalOrder } from 'utils';
import { daysToSeconds, secondsToDays } from 'utils';
import { ResourceCreateFormData, ResourceUpdateFormData } from 'utils';
import { TrialPolicyService } from '../trial-policy.service';
import { KeyValuePipe } from '@angular/common';
import { CtxButtonComponent } from 'ng-ui';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CtxStepComponent } from 'ng-ui';
import { CtxStepperComponent as CtxStepperComponent_1 } from 'ng-ui';
import { CtxAlertComponent } from 'ng-ui';

@Component({
  selector: 'ctx-trial-policy-form',
  templateUrl: './trial-policy-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    ReactiveFormsModule,
    CtxAlertComponent,
    MatDialogContent,
    CtxStepperComponent_1,
    CtxStepComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
    KeyValuePipe,
  ],
})
export class TrialPolicyFormComponent extends CtxForm implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceCreateFormData | ResourceUpdateFormData,
    public dialogRef: MatDialogRef<TrialPolicyFormComponent>,
    private fb: FormBuilder,
    public trialPolicyService: TrialPolicyService
  ) {
    super();
  }

  ALL_COUNTRIES = ALL_COUNTRIES;
  originalOrder = originalOrder;

  /** Ref to Tab Component */
  @ViewChild(CtxStepperComponent) ctxTab: CtxStepperComponent;

  /** Controls in Details Tab */
  detailsTab: FormControl;
  /** Controls in Advanced Tab */
  advancedTab: FormControl;

  ngOnInit() {
    this.formGroup = this.fb.group({
      name: [
        this.getInitialControlValue('name', this.dialogData),
        Validators.maxLength(256),
      ],
      fingerprintMatchingStrategy: [
        this.getInitialControlValue(
          'fingerprintMatchingStrategy',
          this.dialogData
        ) || 'fuzzy',
        Validators.required,
      ],
      trialLength: [
        secondsToDays(
          this.getInitialControlValue('trialLength', this.dialogData)
        ) || 1,
        [Validators.min(1), CtxValidators.maxDays],
      ],
      allowedCountries: [
        this.getInitialControlValue('allowedCountries', this.dialogData),
        Validators.maxLength(512),
      ],
      disallowedCountries: [
        this.getInitialControlValue('disallowedCountries', this.dialogData),
        Validators.maxLength(512),
      ],
      allowedIpRanges: [
        this.getInitialControlValue('allowedIpRanges', this.dialogData),
        Validators.maxLength(512),
      ],
      allowedIpAddresses: [
        this.getInitialControlValue('allowedIpAddresses', this.dialogData),
        Validators.maxLength(512),
      ],
      disallowedIpAddresses: [
        this.getInitialControlValue('disallowedIpAddresses', this.dialogData),
        Validators.maxLength(512),
      ],
      allowVmActivation: [
        this.getInitialControlValue('allowVmActivation', this.dialogData, true),
      ],
      disableGeoLocation: [
        this.getInitialControlValue(
          'disableGeoLocation',
          this.dialogData,
          false
        ),
      ],
      userLocked: [
        this.getInitialControlValue('userLocked', this.dialogData, false),
      ],
      allowContainerActivation: [
        this.getInitialControlValue(
          'allowContainerActivation',
          this.dialogData,
          false
        ),
      ],
    });

    // Details tab control
    this.detailsTab = this.fb.control([
      this.formGroup.get('name'),
      this.formGroup.get('fingerprintMatchingStrategy'),
      this.formGroup.get('trialLength'),
    ]);

    // Advanced tab control
    this.advancedTab = this.fb.control([
      this.formGroup.get('allowedCountries'),
      this.formGroup.get('disallowedCountries'),
      this.formGroup.get('allowedIpRanges'),
      this.formGroup.get('allowedIpAddresses'),
      this.formGroup.get('disallowedIpAddresses'),
      this.formGroup.get('allowVmActivation'),
      this.formGroup.get('disableGeoLocation'),
      this.formGroup.get('userLocked'),
      this.formGroup.get('allowContainerActivation'),
    ]);
  }

  get formValue() {
    const value = Object.assign({}, this.formGroup.value);
    value.allowedIpRanges = this.csvToArray(value.allowedIpRanges);
    value.disallowedIpAddresses = this.csvToArray(value.disallowedIpAddresses);
    value.allowedIpAddresses = this.csvToArray(value.allowedIpAddresses);

    // Convert values that are displayed in days to seconds for the Web API request
    value.trialLength = Math.floor(daysToSeconds(value.trialLength));
    return value;
  }
}

import { CTX_ADMIN_ROUTES } from 'ng-ui';
import { CtxErrorPageComponent } from 'admin-portal/pages/error-page/error-page.component';
import { CtxPageLayoutComponent } from 'admin-portal/pages/_layouts/page-layout/page-layout.component';
import { resolveSegments } from 'admin-portal/resolvers/resolve-segments';
import {
  resolveAccount,
  resolveCards,
  resolvePlans,
  resolveProfile,
} from 'admin-portal/resolvers/resolve-to-cache';
import {
  canAccessAuthRoutes,
  canAccessDashboard,
} from 'libs/ng-ui/src/lib/_services/authn.service';
import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    canActivate: [canAccessDashboard],
    component: CtxPageLayoutComponent,
    resolve: {
      profile: resolveProfile,
      account: resolveAccount,
      segments: resolveSegments,
      plans: resolvePlans,
      cards: resolveCards,
    },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        redirectTo: CTX_ADMIN_ROUTES.getNode('home').path,
        pathMatch: 'full',
      },
      {
        path: CTX_ADMIN_ROUTES.getNode('home').path,
        children: [
          {
            path: '',
            redirectTo: CTX_ADMIN_ROUTES.getLeaf('overview').leafNode.path,
            pathMatch: 'full',
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('overview').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('overview').leafNode.label
            ),
            loadChildren: () => {
              return import('admin-portal/pages/overview/overview.routes').then(
                (m) => {
                  return m.routes;
                }
              );
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('products').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('products').leafNode.label
            ),
            loadChildren: () => {
              return import('admin-portal/pages/products/products.routes').then(
                (m) => {
                  return m.routes;
                }
              );
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('tags').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('tags').leafNode.label
            ),
            loadChildren: () => {
              return import('admin-portal/pages/tags/tags.routes').then((m) => {
                return m.routes;
              });
            },
          },
        ],
      },
      {
        path: CTX_ADMIN_ROUTES.getNode('entitlements').path,
        children: [
          {
            path: '',
            redirectTo: CTX_ADMIN_ROUTES.getLeaf('features').leafNode.path,
            pathMatch: 'full',
          },

          {
            path: CTX_ADMIN_ROUTES.getLeaf('features').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('features').leafNode.label
            ),
            loadChildren: () => {
              return import('admin-portal/pages/features/features.routes').then(
                (m) => {
                  return m.routes;
                }
              );
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('entitlement-sets').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('entitlement-sets').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/entitlement-set/entitlement-set.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
        ],
      },
      {
        path: CTX_ADMIN_ROUTES.getNode('feature-management').path,
        children: [
          {
            path: '',
            redirectTo: CTX_ADMIN_ROUTES.getLeaf('feature-flags').leafNode.path,
            pathMatch: 'full',
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('feature-flags').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('feature-flags').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/feature-flags/feature-flags.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('product-versions').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('product-versions').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/product-versions/product-versions.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
        ],
      },
      {
        path: CTX_ADMIN_ROUTES.getNode('release-management').path,
        children: [
          {
            path: '',
            redirectTo: CTX_ADMIN_ROUTES.getLeaf('releases').leafNode.path,
            pathMatch: 'full',
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('releases').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('releases').leafNode.label
            ),
            loadChildren: () => {
              return import('admin-portal/pages/releases/releases.routes').then(
                (m) => {
                  return m.routes;
                }
              );
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('files').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('files').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/release-files/release-files.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('platforms').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('platforms').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/release-platforms/release-platforms.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('channels').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('channels').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/release-channels/release-channels.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
        ],
      },
      {
        path: CTX_ADMIN_ROUTES.getNode('trial-management').path,
        children: [
          {
            path: '',
            redirectTo:
              CTX_ADMIN_ROUTES.getLeaf('trial-activations').leafNode.path,
            pathMatch: 'full',
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('trial-activations').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('trial-activations').leafNode.label
            ),
            loadChildren: () => {
              return import('admin-portal/pages/trials/trials.routes').then(
                (m) => {
                  return m.routes;
                }
              );
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('trial-policies').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('trial-policies').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/trial-policies/trial-policies.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
        ],
      },
      {
        path: CTX_ADMIN_ROUTES.getNode('activation-management').path,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: CTX_ADMIN_ROUTES.getLeaf('activations').leafNode.path,
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('activation-logs').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('activation-logs').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/activation-logs/activation-logs.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('activations').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('activations').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/activations/activations.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
        ],
      },
      {
        path: CTX_ADMIN_ROUTES.getNode('license-management').path,
        children: [
          {
            path: '',
            redirectTo: CTX_ADMIN_ROUTES.getLeaf('licenses').leafNode.path,
            pathMatch: 'full',
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('licenses').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('licenses').leafNode.label
            ),
            loadChildren: () => {
              return import('admin-portal/pages/licenses/licenses.routes').then(
                (m) => {
                  return m.routes;
                }
              );
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('license-templates').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('license-templates').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/license-templates/license-templates.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('maintenance-policies').leafNode
              ?.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('maintenance-policies').leafNode?.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/maintenance-policies/maintenance-policies.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
        ],
      },
      {
        path: CTX_ADMIN_ROUTES.getNode('customers').path,
        children: [
          {
            path: '',
            redirectTo: CTX_ADMIN_ROUTES.getLeaf('users').leafNode.path,
            pathMatch: 'full',
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('users').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('users').leafNode.label
            ),
            loadChildren: () => {
              return import('admin-portal/pages/users/users.routes').then(
                (m) => {
                  return m.routes;
                }
              );
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('organizations').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('organizations').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/organizations/organizations.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('resellers').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('resellers').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/resellers/resellers.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
        ],
      },
      {
        path: CTX_ADMIN_ROUTES.getNode('developer').path,
        children: [
          {
            path: '',
            redirectTo: CTX_ADMIN_ROUTES.getLeaf('webhooks').leafNode.path,
            pathMatch: 'full',
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('webhooks').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('webhooks').leafNode.label
            ),
            loadChildren: () => {
              return import('admin-portal/pages/webhooks/webhooks.routes').then(
                (m) => {
                  return m.routes;
                }
              );
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('webhook-event-logs').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('webhook-event-logs').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/webhook-event-logs/webhook-event-logs.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('access-tokens').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('access-tokens').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/access-tokens/access-tokens.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('sdk-downloads').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('sdk-downloads').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/downloads/downloads.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('automated-emails').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('automated-emails').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/automated-emails/automated-emails.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('automated-email-event-logs')
              .leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('automated-email-event-logs').leafNode
                .label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/automated-email-event-logs/automated-email-event-logs.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('audit-logs').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('audit-logs').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/audit-logs/audit-logs.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
        ],
      },
      {
        path: CTX_ADMIN_ROUTES.getNode('settings').path,
        children: [
          {
            path: '',
            redirectTo: CTX_ADMIN_ROUTES.getLeaf('profile').leafNode.path,
            pathMatch: 'full',
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('profile').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('profile').leafNode.label
            ),
            loadChildren: () => {
              return import('admin-portal/pages/profile/profile.routes').then(
                (m) => {
                  return m.routes;
                }
              );
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('customizations').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('customizations').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/whitelabeling/whitelabeling.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('users').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('users').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/whitelabeling/whitelabeling.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('billing-and-plans').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('billing-and-plans')?.leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/billing-and-plans/billing-and-plans.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('roles').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('roles').leafNode.label
            ),
            loadChildren: () => {
              return import('admin-portal/pages/roles/roles.routes').then(
                (m) => {
                  return m.routes;
                }
              );
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('saved-filters').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('saved-filters').leafNode.label
            ),
            loadChildren: () => {
              return import('admin-portal/pages/segments/segments.routes').then(
                (m) => {
                  return m.routes;
                }
              );
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('team').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('team').leafNode.label
            ),
            loadChildren: () => {
              return import('admin-portal/pages/team/team.routes').then((m) => {
                return m.routes;
              });
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('account').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('account').leafNode.label
            ),
            loadChildren: () => {
              return import('admin-portal/pages/account/account.routes').then(
                (m) => {
                  return m.routes;
                }
              );
            },
          },
          {
            path: CTX_ADMIN_ROUTES.getLeaf('server-info').leafNode.path,
            title: CTX_ADMIN_ROUTES.getRouteTitle(
              CTX_ADMIN_ROUTES.getLeaf('server-info').leafNode.label
            ),
            loadChildren: () => {
              return import(
                'admin-portal/pages/on-premise/on-premise-settings.routes'
              ).then((m) => {
                return m.routes;
              });
            },
          },
        ],
      },
    ],
  },
  {
    path: 'error',
    canActivate: [canAccessDashboard],
    component: CtxErrorPageComponent,
    pathMatch: 'full',
  },
  {
    canActivate: [canAccessAuthRoutes],
    title: CTX_ADMIN_ROUTES.getRouteTitle(
      CTX_ADMIN_ROUTES.getNode('auth').label
    ),
    path: CTX_ADMIN_ROUTES.getNode('auth').path,
    loadChildren: () => {
      return import('admin-portal/pages/auth/auth.routes').then((m) => {
        return m.routes;
      });
    },
  },
  {
    path: CTX_ADMIN_ROUTES.getNode('pay-invoice').path,
    title: CTX_ADMIN_ROUTES.getRouteTitle(
      CTX_ADMIN_ROUTES.getNode('pay-invoice').label
    ),
    loadChildren: () => {
      return import('admin-portal/pages/pay-invoice/pay-invoice.routes').then(
        (m) => {
          return m.routes;
        }
      );
    },
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

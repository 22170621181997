<h2 mat-dialog-title>{{ dialogData.mode }} Entitlement Set</h2>

@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">
  {{ apiMessage.text }}
</ctx-alert>
}

<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, dialogData, dialogRef, tab)"
>
  <mat-dialog-content>
    <ctx-tab-group #tab>
      <ctx-tab label="Details" icon="DETAILS" [stepControl]="detailsTab">
        <section class="flex flex-col gap-4">
          <!-- Name -->
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="name"
              matInput
              required
            />
            <mat-error>{{ getControlError(formGroup, 'name') }}</mat-error>
          </mat-form-field>

          <!-- Display Name -->
          <mat-form-field>
            <mat-label>Display Name</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="displayName"
              matInput
              required
            />
            <mat-error>{{
              getControlError(formGroup, 'displayName')
            }}</mat-error>
          </mat-form-field>

          <!-- Description -->
          <mat-form-field>
            <mat-label>Description</mat-label>
            <textarea
              rows="1"
              matInput
              formControlName="description"
            ></textarea>
            <mat-error>{{
              getControlError(formGroup, 'description')
            }}</mat-error>
          </mat-form-field>
        </section>
      </ctx-tab>
      <ctx-tab
        label="Features"
        icon="FEATURE_FLAGS"
        [stepControl]="featuresTab"
      >
        <!-- List of selected features -->
        <div formArrayName="features" class="flex flex-col gap-4">
          @for (feature of featuresTab.controls; let i = $index; track feature)
          {
          <span [formGroupName]="i" class="flex flex-row gap-2 items-start">
            <mat-form-field class="flex-grow">
              <mat-label>Feature</mat-label>
              <mat-select
                formControlName="featureId"
                required
                (selectionChange)="onFeatureSelected()"
              >
                @for (featureOption of features; track featureOption.id) {
                <mat-option
                  [disabled]="isFeatureSelected(featureOption.id)"
                  [value]="featureOption.id"
                >
                  {{ featureOption.displayName }}
                </mat-option>
                }
              </mat-select>
              <mat-error>{{ getControlError(feature, 'featureId') }}</mat-error>
            </mat-form-field>

            <mat-form-field class="flex-grow">
              <mat-label>Value</mat-label>
              <input matInput formControlName="value" required />
              <mat-error>{{ getControlError(feature, 'value') }}</mat-error>
            </mat-form-field>
            <!-- Hidden field to store entitlement ID -->
            <input type="hidden" formControlName="entitlementId" />
            <button
              ctx-button--secondary
              type="button"
              color="accent"
              actionIcon="CLEAR"
              (click)="removeFeature(i)"
            ></button>
          </span>
          }
        </div>

        <div class="flex justify-center w-full my-4">
          <button
            type="button"
            ctx-button--secondary
            (click)="addFeature()"
            [disabled]="availableFeatures.length === 0"
          >
            Add Feature
          </button>
        </div>
      </ctx-tab>
    </ctx-tab-group>
  </mat-dialog-content>

  <!-- Buttons -->
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      {{ dialogData.mode }}
    </button>
  </mat-dialog-actions>
</form>

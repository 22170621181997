import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import {
  CryptlexApiService,
  CtxButtonComponent,
  DataCacheService,
  LoaderComponent,
} from 'ng-ui';
import { MatTableModule } from '@angular/material/table';
import { getDisplayNameForId } from 'utils';

@Component({
  selector: 'ctx-view-features-modal',
  templateUrl: './view-features-modal.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,

    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
    MatTableModule,
    NgIf,
    LoaderComponent,
  ],
})
export class ViewFeaturesModalComponent implements OnInit {
  displayedColumns: string[] = ['name', 'value']; // Adjust based on your actual feature object properties
  features: Record<string, any>[] = [];
  isLoading = false;
  hasError = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private apiService: CryptlexApiService,
    public dataCacheService: DataCacheService
  ) {}

  ngOnInit() {
    this.loadFeatures(this.dialogData.resource.id);
  }
  getDisplayNameForId = getDisplayNameForId;
  /**
   * Fetches features for a given ID
   * Manages loading state and error handling
   */
  private async loadFeatures(entitlementSetId: string): Promise<void> {
    this.isLoading = true;

    try {
      const response = await this.apiService.getList(
        `/v3/entitlement-sets/${entitlementSetId}/feature-entitlements`
      );
      this.features = response.body;
    } catch (error) {
      this.hasError = true;
      this.features = [];
    } finally {
      this.isLoading = false;
    }
  }
}

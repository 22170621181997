import { CtxEnvironmentName, CtxProjectName, UnixTimestamp } from 'utils';
import type { ScriptService, ThemeManagerService } from '../..';

/**
 * @returns True if Intercom bubble is to be shown.
 */
export function isIntercomVisible(
  envName: CtxEnvironmentName,
  project: CtxProjectName
) {
  if (project === 'admin-portal' && envName === 'production') {
    return true;
  } else {
    return false;
  }
}

type IntercomMessengerAttributes = {
  /** API Base URL */
  api_base: string;
  /** The app_id of your Intercom app which will indicate where to store any data */
  app_id: string;
  /** The CSS selector of an element to trigger Intercom("show") in order to activate the messenger (See docs). To target an element by ID: "#id_of_element". To target elements by class ".classname_of_elements" */
  custom_launcher_selector?: string;
  /** Dictate the alignment of the default launcher icon to be on the left/right. Possible values: "left" or "right" (any other value is treated as right). (See docs) */
  alignment?: string;
  /** Move the default launcher icon vertically. Padding from bottom of screen. Minimum value: 20. Does not work on mobile. (See docs) */
  vertical_padding?: number;
  /** Move the default launcher icon horizontally. Padding from right side of screen Minimum value: 20. Does not work on mobile. (See docs)  */
  horizontal_padding?: number;
  /** Hide the default launcher icon. Setting to false will forcefully show the launcher icon (See docs) */
  hide_default_launcher?: boolean;
  /** Time in milliseconds for the Intercom session to be considered active.A value of 5 * 60 * 1000 would set the expiry time to be 5 minutes  */
  session_duration?: number;
  /** Used in button links and more to highlight and emphasise */
  action_color?: string;
  /** Used behind your team profile and other attributes */
  background_color?: string;
  /** Custom attribute to store dashboard URL */
  dashboard_url?: string;
  /** Custom attribute to store dashboard version */
  dashboard_version?: string;
};

export const IntercomDarkTheme = {
  action_color: '#fad7ff',
  background_color: '#662f78',
};

export const IntercomLightTheme = {
  action_color: '#330044',
  background_color: '#fad7ff',
};

/**
 * Initialize Intercom with Cryptlex APP_ID using ScriptService
 */
export async function initializeIntercom(
  project: CtxProjectName,
  envName: CtxEnvironmentName,
  version: string,
  scriptService: ScriptService,
  themeManager: ThemeManagerService
) {
  if (isIntercomVisible(envName, project)) {
    const DEFAULT_INTERCOM_CONFIG: IntercomMessengerAttributes = {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'r8pcgmw9',
      horizontal_padding: 24,
      vertical_padding: 32,
      dashboard_url: (window as any).location.origin,
      dashboard_version: version,
    };
    scriptService.loadIntercom().then((loaded) => {
      if (loaded) {
        const Intercom = (window as any).Intercom;
        if (Intercom) {
          Intercom('boot', DEFAULT_INTERCOM_CONFIG);

          // Update Intercom theme
          if (themeManager && themeManager.isDarkMode) {
            updateIntercom(IntercomDarkTheme);
          } else {
            updateIntercom(IntercomLightTheme);
          }
        }
      }
    });
  }
}
/**
 * If you have the Inbox product (combined with another product like Messages) you should call the Intercom shutdown method to clear your users’
 * conversations anytime they logout of your application. Otherwise, the cookie we use to track who was most recently logged in on a given device
 * or computer will keep these conversations in the Messenger for one week.
 */
export function shutdownIntercom() {
  const Intercom = (window as any).Intercom;
  if (Intercom) {
    Intercom('shutdown');
  }
}

/**
 * Update information for Intercom
 * @param body
 */
export function updateIntercom(body: IntercomUpdateConfig) {
  const Intercom = (window as any).Intercom;
  if (Intercom) {
    Intercom('update', body);
  }
}

/**
 * Show messages tab from Intercom
 */
export function showIntercomMessages() {
  const Intercom = (window as any).Intercom;
  if (Intercom) {
    Intercom('showSpace', 'messages');
  }
}

/**
 * Open a new message from Intercom that allows sending feedback
 */
export function draftIntercomFeedbackMessage() {
  const Intercom = (window as any).Intercom;
  if (Intercom) {
    Intercom(
      'showNewMessage',
      'Hello! I have some feedback to share about the new admin portal. \n\n'
    );
  }
}

/**
 * Open a new message from Intercom that allows sending feedback
 */
export function draftIntercomBugReportMessage() {
  const Intercom = (window as any).Intercom;
  if (Intercom) {
    Intercom(
      'showNewMessage',
      'Hello! I have encountered a bug on the new admin portal. \n\n'
    );
  }
}

/**
 * Open a new message from Intercom that allows asking for the account alias
 */
export function draftIntercomForgotAccountAlias() {
  const Intercom = (window as any).Intercom;
  if (Intercom) {
    Intercom(
      'showNewMessage',
      'Hello! I have forgotten the account alias for my email.'
    );
  }
}

/** open intercom */
export function openIntercom() {
  const Intercom = (window as any).Intercom;
  if (Intercom) {
    Intercom('showNewMessage');
  }
}
type IntercomCompany = {
  id: string;
  name?: string;
  created_at?: UnixTimestamp;
  monthly_spend?: number;
  plan?: string;
  size?: string;
  website?: string;
  industry?: string;
  region?: string;
};

type IntercomAvatar = {
  type: 'avatar';
  image_url: string;
};

type IntercomUpdateConfig = {
  /** 	The email address of the currently logged in user (Only applicable to users) */
  email?: string;
  /** The user_id address of the currently logged in user (Only applicable to users) */
  user_id?: string;
  /** The Unix timestamp (in seconds) when the user signed up to your app (Only applicable to users) */
  created_at?: UnixTimestamp;
  /** Name of the current user/lead */
  name?: string;
  /** Phone number of the current user/lead */
  phone?: string;

  /** CUSTOM - General stats of the account. */
  stats?: string;

  /** CUSTOM - Role of the current user. */
  user_role?: string;

  /** Sets the [unsubscribe status]((https://docs.intercom.com/faqs-and-troubleshooting/unsubscribing-users/how-do-i-unsubscribe-users-from-receiving-emails) of the record */
  unsubscribed_from_emails?: boolean;
  /** Set the messenger language programmatically (instead of relying on browser language settings) */
  language_override?: string;
  /** Set the avatar/profile image associated to the current record (typically gathered via social profiles via email address) */
  avatar?: IntercomAvatar;
  /** Used for identity verification (Only applicable to users) */
  user_hash?: string;
  /**
   * Current user's company (Only applicable to users)For field definitions see Company Object in the section
   * belowNote: Company ID and company name are the minimum requirements to pass a company into Intercom.  */
  company?: IntercomCompany;
  /** An array of companies the user is associated to (Only applicable to users) */
  companies?: IntercomCompany[];

  /** Used in button links and more to highlight and emphasise */
  action_color?: string;
  /** Used behind your team profile and other attributes */
  background_color?: string;
  /** Custom attribute to store dashboard URL */
  dashboard_url?: string;
  /** Custom attribute to store dashboard version */
  dashboard_version?: string;
};

<h2 mat-dialog-title>Features</h2>
<mat-dialog-content>
  <!-- Loading state -->
  @if ( isLoading ) {
  <ctx-loader class="flex justify-center items-center w-full"></ctx-loader>
  }@else { @if (features.length ) {
  <table mat-table [dataSource]="features">
    <!-- Feature Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="font-bold">Name</th>
      <td mat-cell *matCellDef="let feature">
        {{
          getDisplayNameForId(
            this.dataCacheService.getCachedValues('feature'),
            feature.featureId
          )
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef class="font-bold">Value</th>
      <td mat-cell *matCellDef="let feature">{{ feature.value }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  }@else {
  <section class="flex flex-row gap-1">
    <p>The requested resource is empty.</p>
  </section>
  }}
</mat-dialog-content>
<mat-dialog-actions>
  <button ctx-button--primary matDialogClose type="button">Close</button>
</mat-dialog-actions>

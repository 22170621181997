import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  CtxAccountResponse,
  CtxProjectName,
  ResourceCreateFormData,
  ResourceName,
} from 'utils';
import { AccountDetailsUpdateFormComponent } from './account-email-update-form/account-details-update-form.component';
import { AccountStatusFormComponent } from './account-status-form/account-status-form.component';
import { CryptlexApiService } from 'ng-ui';
import { AuthnService } from 'ng-ui';
import { DataCacheService } from 'ng-ui';
import { ResourceService } from 'ng-ui';
import { PlanChangeFormComponent } from '../billing-and-plans/plan-change-form/plan-change-form.component';
import {
  CtxFormMode,
  ResourceDeleteFormData,
  ResourceUpdateFormData,
} from 'utils';
import { reloadAfterSuccess } from 'utils';
import { GoogleClientIdFormComponent } from './google-client-id-form/google-client-id-form.component';
import { TwoStepDeletionComponent } from 'ng-ui';
import { AccountAliasFormComponent } from './account-alias-form/account-alias-form.component';
import { UpdateAssetsFormComponent } from '../whitelabeling/update-assets-form/update-assets-form.component';
import { ComponentType } from '@angular/cdk/portal';
import { ColorChangeFormComponent } from '../whitelabeling/color-change-form/color-change-form.component';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';
import { CustomDomainsFormComponent } from './custom-domains-form/custom-domains-form.component';
import { DeactivateServerComponent } from './deactivate-server/deactivate-server.component';

export type CtxAccountStatus = 'active' | 'inactive';
export type AccountAliasResponse = { accountAlias: string; company: string };

@Injectable({
  providedIn: 'root',
})
export class AccountService extends ResourceService<any, CtxAccountResponse> {
  override resourceName: ResourceName = 'account';
  override resourceApiPath = '/v3/accounts';
  constructor(
    apiService: CryptlexApiService,
    dataCache: DataCacheService,
    private dialog: MatDialog,
    private router: Router,
    private authn: AuthnService,
    permissionsService: PermissionsService
  ) {
    super(apiService, dataCache, permissionsService);
  }

  /**
   * @returns Promise that returns account aliases for the email parameter when resolved
   */
  getAccountAliases(
    email: string
  ): Promise<HttpResponse<AccountAliasResponse[]>> {
    return this.apiService.get(`${this.resourceApiPath}/account-aliases`, {
      email,
    });
  }

  /**
   * Launches a dialog that allows the user to update the notification email
   */
  async launchCompanyDetailsUpdationDialog() {
    await this._launchUpdateDialog(AccountDetailsUpdateFormComponent);
  }

  updateStatus(id: string, body: any): Promise<HttpResponse<any>> {
    return this.apiService
      .put(`${this.resourceApiPath}/${id}/status`, body)
      .then((response) => {
        // Invalidates any in-memory cache held in the DataCacheService
        this.dataCacheService.invalidateCache(this.resourceName);
        return response;
      });
  }
  /**
   *  Launches a dialog that allows the user activate/deactivate the account
   */
  async launchStatusUpdationDialog() {
    const _dialog = this.dialog.open<any, ResourceUpdateFormData>(
      AccountStatusFormComponent,
      {
        data: {
          mode: CtxFormMode.Update,
          resource: this.dataCacheService.getCachedValues('account'),
          update: this.updateStatus.bind(this),
        },
      }
    );

    await reloadAfterSuccess(_dialog.afterClosed(), this.router);
  }

  /**
   *  Launches a dialog that allows changing colors
   */
  async launchColorUpdationDialog() {
    await this._launchUpdateDialog(ColorChangeFormComponent);
  }

  /**
   * Unlike this.update, this function is a PUT request
   * @returns Promise that returns Account Statues when resolved
   */
  updateAccountStatus(id: string, body: any): Promise<HttpResponse<any>> {
    return this.apiService
      .put(`${this.resourceApiPath}/${id}/status`, body)
      .then((response) => {
        // Invalidates any in-memory cache held in the DataCacheService
        this.dataCacheService.invalidateCache(this.resourceName);
        return response;
      });
  }

  /**
   * Launches a dialog that allows the user to delete the account
   */
  launchAccountDeletionDialog() {
    this.dialog.open<any, ResourceDeleteFormData>(TwoStepDeletionComponent, {
      data: {
        delete: this.delete.bind(this),
        deletionMessage: `Deleting your account will permanently remove all of your data and cannot be undone.
        If you wish to temporarily deactivate your account, please close this dialog and use the
        'Deactivate Account' option instead. Please note that upon deletion of your account, any
        agreements or contracts between you and our organization will be terminated.`,
        resources: [{ id: this.authn.accountId }],
      },
    });
  }

  /**
   * @returns Promise that resolves to response of DELETE request for the Account
   */
  override delete(id: string): Promise<HttpResponse<any>> {
    return this.apiService
      .delete(`${this.resourceApiPath}/${id}`)
      .then((response) => {
        this.authn.logout();
        return response;
      });
  }

  /**
   * * @returns Promise that resolves to response of Deactivate License for on-premise server request
   */

  async deactivateServer(body = {}): Promise<HttpResponse<any>> {
    return this.apiService
      .post(
        `${this.resourceApiPath}/${
          this.dataCacheService.getCachedValues('account')?.id
        }/deactivate-license`,
        body
      )
      .then((response) => {
        this.authn.logout();
        return response;
      });
  }

  /** Launch a dialog that allows super-admin to deactivate license on server for on-premise */

  async launchDeactivateServerLicense() {
    this.dialog.open<any, ResourceCreateFormData>(DeactivateServerComponent, {
      data: {
        mode: CtxFormMode.Create,
        create: this.deactivateServer.bind(this),
      },
    });
  }

  /**
   * Launches a dialog that allows the user to change their subscription plan
   */
  async launchPlanChangeDialog(messageDialogClose?: () => void) {
    const _dialog = this.dialog.open<any, ResourceUpdateFormData>(
      PlanChangeFormComponent,
      {
        data: {
          resource: this.dataCacheService.getCachedValues('account'),
          update: this.updatePlan.bind(this),
          mode: CtxFormMode.Update,
        },
        minWidth: '60vw',
      }
    );

    await reloadAfterSuccess(
      _dialog.afterClosed(),
      this.router,
      messageDialogClose
    );
  }

  /**
   * @returns Promise that resolves to response of Plan Change request to Web API
   */
  updatePlan(id: string, body: any): Promise<HttpResponse<any>> {
    return this.apiService
      .put(`${this.resourceApiPath}/${id}/plan`, body)
      .then((response) => {
        this.dataCacheService.invalidateCache('account');
        // We  show trail snackbar based on the info present in profile.
        this.dataCacheService.invalidateCache('profile');
        return response;
      });
  }

  updateCustomDomains(id: string, body: any): Promise<HttpResponse<any>> {
    return this.apiService
      .patch(`${this.resourceApiPath}/${id}/custom-domains`, body)
      .then((response) => {
        this.dataCacheService.invalidateCache('account');
        return response;
      });
  }

  /** Launches a dialog that allows the user to configure Account Alias */
  async launchAccountAliasDialog(portal: 'customer' | 'reseller') {
    const _dialog = this.dialog.open<any, ResourceUpdateFormData>(
      AccountAliasFormComponent,
      {
        data: {
          mode: CtxFormMode.Update,
          resource: {
            accountAlias:
              this.dataCacheService.getCachedValues('account').accountAlias,
            id: this.dataCacheService.getCachedValues('account').id,
            portal,
          },
          update: this.update.bind(this),
        },
      }
    );

    await reloadAfterSuccess(_dialog.afterClosed(), this.router);
  }

  async launchCustomDomainsDialog(portal: CtxProjectName) {
    const _dialog = this.dialog.open<any, ResourceUpdateFormData>(
      CustomDomainsFormComponent,
      {
        data: {
          mode: CtxFormMode.Update,
          resource: {
            customerPortalDomain:
              this.dataCacheService.getCachedValues('account')
                .customerPortalDomain,
            resellerPortalDomain:
              this.dataCacheService.getCachedValues('account')
                .resellerPortalDomain,
            adminPortalDomain:
              this.dataCacheService.getCachedValues('account')
                .adminPortalDomain,
            id: this.dataCacheService.getCachedValues('account').id,
            portal,
          },
          update: this.updateCustomDomains.bind(this),
        },
      }
    );

    await reloadAfterSuccess(_dialog.afterClosed(), this.router);
  }
  /**
   * Launches a dialog that allows the user to configure Google Client ID
   */
  async launchGoogleClientIdDialog() {
    await this._launchUpdateDialog(GoogleClientIdFormComponent);
  }

  async launchCustomizeDashboard(): Promise<void> {
    await this._launchUpdateDialog(UpdateAssetsFormComponent);
  }

  /** Base function for account updation */
  private async _launchUpdateDialog(
    component: ComponentType<any>
  ): Promise<void> {
    const _dialog = this.dialog.open<any, ResourceUpdateFormData>(component, {
      data: {
        mode: CtxFormMode.Update,
        resource: this.dataCacheService.getCachedValues('account'),
        update: this.update.bind(this),
      },
    });

    await reloadAfterSuccess(_dialog.afterClosed(), this.router);
  }

  syncSubscription(id: string): Promise<HttpResponse<any>> {
    return this.apiService
      .post(`${this.resourceApiPath}/${id}/sync-subscription`, {})
      .then((response) => {
        return response;
      });
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxForm } from 'ng-ui';
import { ResourceCreateFormData, ResourceUpdateFormData } from 'utils';
import { CtxButtonComponent } from 'ng-ui';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CtxAlertComponent } from 'ng-ui';

@Component({
  selector: 'ctx-feature-form',
  templateUrl: './feature-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    ReactiveFormsModule,
    MatDialogContent,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
  ],
})
export class FeatureFormComponent extends CtxForm implements OnInit {
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<FeatureFormComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceCreateFormData | ResourceUpdateFormData
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      name: new FormControl(
        {
          value: this.getInitialControlValue('name', this.dialogData),
          disabled: this.dialogData.mode === 'Update',
        },
        [Validators.required, Validators.maxLength(256)]
      ),
      displayName: [
        this.getInitialControlValue('displayName', this.dialogData),
        [Validators.required, Validators.maxLength(256)],
      ],
      description: [
        this.getInitialControlValue('description', this.dialogData),
        Validators.maxLength(256),
      ],
    });
  }
}
